import getCookie from '../utils/get-cookit';
import setCookie from '../utils/set-cookit';

const _element = '.js-form-main';
const $element = $( _element );

if ( $element.length ) {
	const _id   = parseInt($element.data( 'id' ), 10);
	const _name = 'disable-fixed-circle-button';

	$(document).on( 'gform_confirmation_loaded', function(event, formId) {
	    if ( _id == formId ) {
	    	setCookie( _name, true, 356 );

	    	$( 'body' ).addClass( _name );
	    }
	});

	if ( getCookie( _name ) == 'true' ) {
		$( 'body' ).addClass( _name );
	}
}
