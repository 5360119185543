
import { $win } from '../utils/globals';
import AOS from 'aos';

$win.on('load', function() {
	AOS.init({
		duration: 700,
		delay: 100,
		once: true,
	});
});
