/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/scroll-by-data';
import './modules/nav';
import './modules/aos-animations';
import './modules/filter';
import './modules/btn-popup';
import './modules/init-gravity-form';
import './modules/load-more';
