import { $win } from '../utils/globals';

const winLochash = window.location.hash;

function scrollByData( data ) {
	if ( ! data ) {
		return false;
	};

	if ( $( '*[data-id="' + data + '"]' ).length > 0 ) {

		$('body, html').animate({
			scrollTop: $( '*[data-id="' + data + '"]' ).offset().top
		}, 1000);
	};
}

$('.nav-scroll a').each(function () {
	const $this = $(this);
	const href = this.hash;

	if (this.hash) {

		$(this).on('click', function(event) {
			scrollByData( href );
		});

	}
});

$win.on('load', function() {
	if ( winLochash.length > 0 ) {

		$('body, html').scrollTop(0, 0);

		setTimeout(function() {
			scrollByData( winLochash );
		}, 500);
	}
});

