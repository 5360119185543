$( '.js-popup-form' ).on( 'click', function( event ) {
	event.preventDefault();

	$( 'body' ).addClass( 'show-popup' );
});

$( '.js-section-popup .js__close' ).on( 'click', function( event ) {
	event.preventDefault();

	$( 'body' ).removeClass( 'show-popup' );
});

$( 'body' ).on( 'click', function( event ) {
	const $target = $( event.target );
	const _isSestionPopup = $target.parents( '.js-section-popup' ).length || $target.hasClass( 'js-section-popup' );


	if ( _isSestionPopup ) {
		const _isViewport = !$target.parents( '.js__popup' ).length && !$target.hasClass( 'js__popup' );

		if ( _isViewport ) {
			$( 'body' ).removeClass( 'show-popup' );
		}
	}
});
