if ($('.js-load-more').length) {
	$(document).on('click', '.js-load-more', function(e){
		e.preventDefault();

		let $ajaxContainer = $('.js-listing .articles');

		$.ajax({
			url: $(this).attr('href'),
			beforeSend() {
				$ajaxContainer.addClass('ajax-loading');
			},
			success: function( response ) {
				$ajaxContainer.removeClass('ajax-loading');

				$ajaxContainer.append( $(response).find('.js-listing .articles').html() );

				if ($(response).find('.js-listing .js-load-more').length) {
					$('.js-listing .js-load-more').attr( 'href', $(response).find('.js-listing .js-load-more').attr('href') );
				} else {
					$('.js-listing .js-load-more').remove();
				}
			},
			error() {
				$ajaxContainer.html('<h3>Error has occurred please try again!</h3>');
				$ajaxContainer.removeClass('ajax-loading');
			},
			always() {
				$ajaxContainer.removeClass('ajax-loading');
			}
		});
	})
}
