import { $win } from '../utils/globals';
import imagesLoaded from 'imagesloaded';
import "@babel/polyfill";
var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');

jQueryBridget( 'isotope', Isotope, $ );


$win.on('load', function () {
	var $filterItems = $('.filter-items').isotope({
		itemSelector: '.filter-items .filter__item',
		percentPosition: true,
		masonry: {
			columnWidth: '.filter-items .filter__item'
		}
	});

	$('.nav-filter button').on('click', function(){
		var filterClass = $(this).data('filter');
		$filterItems.isotope({ filter: filterClass });
	});
});

